<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div>
      <div v-if="appData">
        <policy-alert :app-data="appData" />

        <policy-summary :app-data="appData" />

        <instructions :instructions-text="instructions" />

        <compliance-details :asset="appData.asset" />

        <strata-contact :strata-company="appData.strataCompany" />

        <claims-history :claims="appData.claims" />

        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.policyReviewed"
          v-slot="{ errors }"
        >
          <div class="row mt-10">
            <span
              class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
            >
              <label>
                <input
                  type="checkbox"
                  :disabled="isReadOnly"
                  v-model="appData.statusFlags.policyReviewed"
                  @change="
                    $emit('updateServerTransactionStatus', 'policyReviewed')
                  "
                />
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2">
              I confirm that the above information has been reviewed and is
              complete.
            </label>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import ComplianceDetails from "./ComplianceDetails";
import StrataContact from "./StrataContact";
import ClaimsHistory from "./ClaimsHistory";

export default {
  name: "ReviewPage",

  components: {
    PolicySummary,
    PolicyAlert,
    Instructions,
    ComplianceDetails,
    StrataContact,
    ClaimsHistory,
  },

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  computed: {
    instructions: function () {
      return (
        "Review Policy, Building, Contact and Claims details. Once you are satisfied that all is complete, " +
        "click the confirmation button below.  If you need to change any details, click the relevant link (i.e blue text) " +
        "to display the CRM record, make the required updates, then refresh the wizard tab."
      );
    },
  },
};
</script>
