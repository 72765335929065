<template>
  <ValidationObserver ref="renewalWizard">
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div class="wizard wizard-3" id="renewal_wizard" data-wizard-state="step-first" data-wizard-clickable="true">
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div v-for="(wizardPage, index) in wizardPages" :key="index" class="wizard-step" style="cursor: default"
                data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span>{{ index + 1 }}</span>{{ wizardPage.title }}
                  </h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body-->
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                <div class="col-xl-12 col-xxl-9">
                  <!--begin: Wizard Form-->
                  <div class="form" id="renewal_wizard_form">
                    <b-alert v-if="wizardAppData && isWizardReadOnly" variant="secondary" show>
                      <div class="text-danger">
                        <i class="fas fa-lock fa-md"></i>
                        This renewal is already finalised and cannot be
                        modified.
                      </div>
                    </b-alert>

                    <!-- ERROR ALERTS -->
                    <b-alert v-if="errorAlert" variant="warning" show>
                      <div class="text-dark" v-html="errorAlert"></div>
                    </b-alert>

                    <!--begin: Wizard Page 1-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <review-page :app-data="wizardAppData" :is-read-only="
                            isWizardReadOnly || acceptedQuoteExists
                          " @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @handleError="handleError" />
                      </div>
                    </div>
                    <!--end: Wizard Page 1-->

                    <!--begin: Wizard Page 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <declaration-page :app-data="wizardAppData" :is-read-only="
                            isWizardReadOnly || acceptedQuoteExists
                          " @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError"
                          :get-client-declaration-email-template="getClientDeclarationEmailTemplate" />
                      </div>
                    </div>
                    <!--end: Wizard Page 2-->

                    <!--begin: Wizard Page 3-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <renewal-page :app-data="wizardAppData"
                        :is-read-only="
                            isWizardReadOnly || acceptedQuoteExists
                          " @overrideDeclaration="overrideDeclarationForm"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @updateWizardAppDataString="updateWizardAppDataString"
                          @handleError="handleError"
                          @refreshData="getWizardAppData"
                          @saveAppData="saveSpecificAppData" />
                      </div>
                    </div>
                    <!--end: Wizard Page 3-->

                    <!--begin: Wizard Page 4-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <insurers-page :app-data="wizardAppData" :is-read-only="
                            isWizardReadOnly || acceptedQuoteExists
                          " @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError"
                          @requestHoldingInsurerEmailTemplate="getHoldingInsurerQuoteSlipEmailTemplate"
                          @requestOtherInsurerEmailTemplate="getInsurerQuoteSlipEmailTemplate" />
                      </div>
                    </div>
                    <!--end: Wizard Page 4-->

                    <!--begin: Wizard Page 5-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <quotes-page :app-data="wizardAppData" :manager-users="managerUsers"
                          :is-current-user-manager="isCurrentUserManager"
                          :is-current-user-also-reviewer="isCurrentUserAlsoReviewer"
                          :is-read-only="
                            isWizardReadOnly || acceptedQuoteExists
                          " @saveQuote="saveQuoteToServer" @setRecommendedQuote="setRecommendedQuote"
                          @applyBrokerFeeToAllQuotes="applyBrokerFeeToAllQuotes" @createQuoteComparisonTable="
                            createQuoteComparisonTable
                          " @statusUpdated="updateUiTransactionStatus"
                          @requestBrokerAdviceReview="requestBrokerAdviceReview"
                          @approveBrokerAdvice="approveOrRejectBrokerAdvice"
                          @rejectBrokerAdvice="approveOrRejectBrokerAdvice" @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError"
                          @quoteRankingUpdated="handleSave" @saveQuoteRanking="onSaveQuoteRanking"
                          @deleteQuoteRequest="deleteCrmQuote"
                          @saveAppData="saveWizardAppData(false)" />
                      </div>
                    </div>
                    <!--end: Wizard Page 5-->

                    <!--begin: Wizard Page 6-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <finalisation-page :app-data="wizardAppData" :is-read-only="isWizardReadOnly"
                          @acceptQuote="acceptQuote" @statusUpdated="updateUiTransactionStatus"
                          @updateServerTransactionStatus="
                            updateServerTransactionStatus
                          " @updateWizardAppDataString="updateWizardAppDataString"
                          @handleError="handleError"
                          @handleConfirmationToInsurerEmail="getConfirmationToInsurerEmailTemplate"
                          />
                      </div>
                    </div>
                    <!--end: Wizard Page 6-->

                    <!--begin: Wizard Page 7-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div v-if="wizardAppData">
                        <invoice-page :app-data="wizardAppData" @statusUpdated="updateUiTransactionStatus"
                          :is-read-only="isWizardReadOnly"
                        @handleClosingToInsurerEmail="getClosingToInsurerEmailTemplate"
                          />
                      </div>
                    </div>
                    <!--end: Wizard Page 7-->

                    <b-alert v-if="wizardAppData && isWizardReadOnly" variant="secondary" show>
                      <div class="text-danger">
                        <i class="fas fa-lock fa-md"></i>
                        This renewal is already finalised and cannot be
                        modified.
                      </div>
                    </b-alert>

                    <!--begin: Wizard Actions -->
                    <div class="d-flex justify-content-between border-top pt-10">
                      <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev" v-show="wizardAppData">
                        Previous Step
                      </button>
                      <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        v-show="wizardAppData && !isLastPage" @click.prevent="goToNextPage">
                        Next Step
                      </button>

                      <button class="btn btn-outline-danger font-weight-bold text-uppercase px-9 py-4"
                        v-show="wizardAppData && isWizardReadOnly" @click.prevent="openReopenTransactionDialog">
                        Reopen Transaction
                      </button>

                      <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4" v-show="
                          wizardAppData &&
                          isLastPage &&
                          !isWizardReadOnly &&
                          wizardAppData.statusFlags.cocSent
                        " @click.prevent="finaliseTransaction">
                        Finalise Transaction
                      </button>
                    </div>
                    <!--end: Wizard Actions -->
                  </div>
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
        <!--end: Wizard-->
      </div>

      <b-modal ref="reopenTransactionDialog" size="lg" no-close-on-backdrop title="Reopen Transaction"
        @ok="reopenTransaction" @hidden="reopenReason = null">
        <ValidationObserver ref="indemnityStatusData">
          <div class="form-group row">
            <label class="col-4 col-form-label text-right">Reason for reopening the renewal<span
                class="text-danger">*</span></label>
            <div class="col-8">
              <ValidationProvider rules="required|max:2000" name="reopenReason" v-slot="{ errors }">
                <b-textarea class="form-control min-rows" v-model="reopenReason" trim></b-textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}
</style>

<script>
import _ from "lodash";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import ReviewPage from "./pages/1-Review/ReviewPage";
import DeclarationPage from "./pages/2-Declaration/DeclarationPage";
import RenewalPage from "./pages/3-Renewal/RenewalPage.vue";
import InsurersPage from "./pages/4-Insurers/InsurersPage.vue";
import QuotesPage from "./pages/5-Quotes/QuotesPage.vue";
import FinalisationPage from "./pages/6-Finalisation/FinalisationPage.vue";
import InvoicePage from "./pages/7-Invoice/InvoicePage.vue";
import Swal from "sweetalert2";

import RenewalDataService from "./pages/services/renewal.data.service"

export default {
  name: "RenewalWizard",
  components: {
    ReviewPage,
    DeclarationPage,
    RenewalPage,
    InsurersPage,
    QuotesPage,
    FinalisationPage,
    InvoicePage,
  },
  data() {
    return {
      wizardPages: [
        {
          id: "wizardPage1",
          title: "Review",
          subtitle: "Review Policy Details",
        },
        {
          id: "wizardPage2",
          title: "Declaration",
          subtitle: "Review & Update Declaration",
        },
        {
          id: "wizardPage3",
          title: "Renewal",
          subtitle: "Review Renewal Details",
        },
        {
          id: "wizardPage4",
          title: "Insurers",
          subtitle: "Select Insurers",
        },
        {
          id: "wizardPage5",
          title: "Quotes",
          subtitle: "Update & Review Quotes",
        },
        {
          id: "wizardPage6",
          title: "Finalisation",
          subtitle: "Finalise Transaction",
        },
        {
          id: "wizardPage7",
          title: "Invoice",
          subtitle: "Create Invoice",
        },
      ],

      userId: null,
      wizardObj: null,
      errorAlert: null,
      wizardAppData: null,
      wizardAppDataString: null,
      users: [],
      reopenReason: null,
      saveDebounced: _.debounce(this.save, 300),
    };
  },

  created() {
    // Create a debounced version of save function
    this.saveDebounced = _.debounce(this.save, 300);  // 300ms delay
  },

  mounted() {
    // Show page loading
    this.startPageLoading();

    // Extract information from URL params
    let queryParams = this.$route.query;
    if (
      !queryParams.userId ||
      !queryParams.transactionId ||
      !queryParams.token
    ) {
      this.handleError("Unauthorised access!");
      return;
    }

    this.userId = queryParams.userId;

    // Initialise RenewalDataService with the token
    this.$renewalDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    // Initialise CommonDataService with the token
    this.$commonDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    // Initialise the UI wizard
    this.initWizard();

    // Get Users
    this.getUsers();

    // Get Application Data
    this.getWizardAppData();

    // If batch parameter is present, get the next transaction ID to enable navigation
    if (queryParams.batch) {
      this.getNextTransactionInBatch();
    }
  },

  computed: {
    managerUsers: function () {
      let managerUsers = [];
      if (Array.isArray(this.users)) {
        // Remove current user from manager-users (shouldn't be able to ask for own approval)
        managerUsers = _.filter(this.users, (user) => {
          return user.id !== this.userId && user.K_ManagerReviewer === true;
        });
      }
      return managerUsers;
    },

    isCurrentUserManager: function () {
      if (Array.isArray(this.users)) {
        let currentUser = _.find(this.users, { id: this.userId });
        if (currentUser) {
          return !!currentUser.K_ManagerReviewer;
        }
      }
      return false;
    },

    isCurrentUserAlsoReviewer: function () {
      if (Array.isArray(this.users)) {
        const currentUser = _.find(this.users, { id: this.userId });
        const reviewUserName = _.get(this.wizardAppData, "transaction.K_BrokerAdviceReviewer");
        if (currentUser && reviewUserName) {
          return currentUser.full_name === reviewUserName;
        }
      }
      return false;
    },

    acceptedQuoteExists: function () {
      let acceptedQuoteId = _.get(this.wizardAppData, "acceptedQuote.id");
      return !!acceptedQuoteId;
    },

    isWizardReadOnly: function () {
      let txnStatus = _.get(this.wizardAppData, "transaction.K_Status");
      return txnStatus === "Completed";
    },

    isLastPage: function () {
      return this.wizardObj && this.wizardObj.isLastStep();
    },
  },

  methods: {
    initWizard: function () {
      // Initialize form wizard
      const wizard = new KTWizard("renewal_wizard", {
        startStep: 1, // Initial active step number
        clickableSteps: false, // Do not allow step clicking
      });

      this.wizardObj = wizard;

      // Before-Change event
      wizard.on("change", (/*wizardObj*/) => {
        this.clearError();
        if (this.wizardAppData) {
          // Save app-data to server
          this.saveWizardAppData();
        }
      });

      // Page-Changed event - Scroll to top of the page
      wizard.on("changed", (wizardObj) => {
        // Save current page number to server
        let currentStep = wizardObj.getStep();
        this.saveWizardCurrentPage(currentStep);

        setTimeout(function () {
          KTUtil.scrollTop();
        }, 500);
      });
    },

    saveSpecificAppData({ field, value, updateCallback }) {
      if (this.isWizardReadOnly) {
        return;
      }

      this.startPageLoading();

      // Update the local data
      _.set(this.wizardAppData, field, value);

      this.$renewalDataService
        .saveWizardAppData(this.wizardAppData)
        .then(({ data }) => {
          this.wizardAppData = data.result;
          this.wizardAppDataString = stringify(this.wizardAppData);

          // Update transaction status on the header
          this.updateUiTransactionStatus();

          this.stopPageLoading();
          updateCallback(true);
        })
        .catch((error) => {
          // Revert the local change if the save failed
          _.set(this.wizardAppData, field, !value);
          this.handleError(error);
          updateCallback(false);
        });
    },

    handleAppDataChange(updatedAppData) {
      this.wizardAppData = updatedAppData;
      this.saveWizardAppData();
    },

    isPageValid: async function (pageNumber) {
      let isValid = false;
      if (!this.$refs || !this.$refs.renewalWizard || !this.wizardAppData) {
        return isValid;
      }

      let page = this.wizardPages[pageNumber - 1];
      if (!page) {
        console.log(`Invalid page number ${pageNumber}`);
        return isValid;
      }

      let wizardPageId = page.id;
      let isDevelopment = process.env.NODE_ENV === "development";

      let childrenPages = this.$refs.renewalWizard.$children;
      if (Array.isArray(childrenPages)) {
        let currentPageComponent = _.find(childrenPages, (child) => {
          return child.$el && child.$el.id === wizardPageId;
        });
        if (!currentPageComponent) {
          if (isDevelopment) {
            console.log(
              `Could not find currentPageComponent for id ${wizardPageId}`
            );
          }
        } else {
          let currentPageRef = currentPageComponent.$refs[wizardPageId];
          if (!currentPageRef) {
            if (isDevelopment) {
              console.log(
                `Could not find currentPageRef for id ${wizardPageId}`
              );
            }
          } else {
            isValid = await currentPageRef.validate();
            if (!isValid && isDevelopment) {
              console.log(currentPageRef.errors);
            }
          }
        }
      }
      return isValid;
    },

    goToNextPage: async function () {
      this.clearError();
      let isNavAllowed = true;

      if (!this.isWizardReadOnly) {
        // Validate the current page
        isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
      }

      if (isNavAllowed) {
        // Save app-data to server
        this.saveWizardAppData();
        // Go to next page
        this.wizardObj.goNext();
      }
    },

    getUsers: function () {
      this.$commonDataService
        .getActiveUsers()
        .then(({ data }) => {
          this.users = data.result || [];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getWizardAppData: function () {
      // Show page loading
      this.startPageLoading();
      this.$renewalDataService
        .getWizardAppData()
        .then(({ data }) => {
          this.wizardAppData = data.result;
          this.wizardAppDataString = stringify(this.wizardAppData);

          // Set wizard title and subtitle
          this.setHeaderInformation();

          // Set starting step for the wizard
          let startStep = 1;
          if (this.isWizardReadOnly) {
            // If transaction is already completed, directly start the wizard on last page.
            startStep = this.wizardPages.length;
          } else if (this.wizardAppData.transaction) {
            // Check if the user was previously on a particular wizard page
            startStep = this.wizardAppData.transaction.K_WizardCurrentPage || 1;
          }

          // Stop page loading
          this.stopPageLoading();

          if (startStep !== 1) {
            setTimeout(() => {
              this.wizardObj.goTo(startStep);
            }, 0);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setHeaderInformation: function () {
      if (this.wizardAppData) {
        // Set wizard title and sub-title in global store so that it can be shown on the header
        if (this.wizardAppData.policy) {
          let policyName = this.wizardAppData.policy.Name;

          let title = `Renewal - ${policyName}`;
          this.$store.commit("setWizardTitle", title);

          if (this.wizardAppData.policy.K_Client) {
            this.$store.commit(
              "setWizardSubtitle",
              this.wizardAppData.policy.K_Client.name
            );
          }
        }

        // Update transaction status on the header
        this.updateUiTransactionStatus();

        // Update Box link on the sticky-toolbar
        this.setTransactionBoxLink();
      }
    },

    updateWizardAppDataString: function () {
      this.wizardAppDataString = stringify(this.wizardAppData);
    },

    updateServerTransactionStatus: function (flagName) {
      let flagValue = this.wizardAppData.statusFlags[flagName];
      this.$renewalDataService
        .updateTransactionStatus(flagName, flagValue)
        .then(({ data }) => {
          let updatedStatus = data.result;
          if (updatedStatus) {
            // Update UI transaction status
            let txn = this.wizardAppData.transaction;
            txn.K_Status = updatedStatus.K_Status;
            txn.K_StatusDetails = updatedStatus.K_StatusDetails;
            txn.K_NextStep = updatedStatus.K_NextStep;
            txn.K_DueInWeek = updatedStatus.K_DueInWeek;
            txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;
            this.updateUiTransactionStatus();

            // Update wizardAppDataString
            this.wizardAppDataString = stringify(this.wizardAppData);

            this.saveWizardAppData();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateUiTransactionStatus: function () {
      if (this.wizardAppData && this.wizardAppData.transaction) {
        let txn = this.wizardAppData.transaction;
        let txnStatus = {
          status: txn.K_Status,
          statusDetails: txn.K_StatusDetails,
          nextStep: txn.K_NextStep,
          dueInWeek: txn.K_DueInWeek,
          weekStartDate: txn.K_WeekStartDate,
        };
        // Set transaction status in global store so that it can be shown on the header
        this.$store.commit("setTransactionStatus", txnStatus);
      }
    },

    setTransactionBoxLink: function () {
      let transactionBoxFolderId = _.get(
        this.wizardAppData,
        "transaction.K_TransactionBoxFolderId"
      );
      if (transactionBoxFolderId) {
        let txnBoxFolderLink = `https://collectiveib.app.box.com/folder/${transactionBoxFolderId}`;

        // Set transaction status in global store so that it can be shown on the header
        this.$store.commit("setTransactionBoxFolderLink", txnBoxFolderLink);
      }
    },

    saveWizardAppData: function (noSpinner) {
      if (this.isWizardReadOnly) {
        return;
      }

      // Save app-data to the server (only if it has changed)
      let currentAppDataString = stringify(this.wizardAppData);
      if (currentAppDataString !== this.wizardAppDataString) {
        if (!noSpinner) {
          this.startPageLoading();
        }

        this.$renewalDataService
          .saveWizardAppData(this.wizardAppData)
          .then(({ data }) => {
            this.wizardAppData = data.result;
            this.wizardAppDataString = stringify(this.wizardAppData);

            // Update transaction status on the header
            this.updateUiTransactionStatus();

            if (!noSpinner) {
              this.stopPageLoading();
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },

    saveWizardCurrentPage: function (pageNumber) {
      if (this.isWizardReadOnly) {
        return;
      }

      this.$commonDataService
        .saveWizardCurrentPage(pageNumber)
        .catch((error) => {
          this.handleError(error);
        });
    },

    overrideDeclarationForm: function (overrideReason) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      this.startPageLoading();
      this.$renewalDataService
        .overrideDeclarationForm(overrideReason)
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    saveQuoteToServer: _.debounce(function (quote) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      this.startPageLoading();
      // Save quote
      this.$renewalDataService
        .saveQuoteDetails(quote)
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();

          // Reset quote comparison data (because it would have changed when quote was updated)
          this.wizardAppData.quoteComparisonData = null;
        })
        .catch((error) => {
          this.handleError(error);
        });
    }, 300),

    deleteCrmQuote: function (quoteId) {
      RenewalDataService
        .deleteQuoteById(quoteId)
        .then(({ data }) => {
          this.$renewalDataService
            .refreshQuotes()
            .then(({ data }) => {
              this.wizardAppData.quotes = data.result;
              this.stopPageLoading();

              this.updateWizardAppDataString();
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
    },

    applyBrokerFeeToAllQuotes: function () {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      let feesAndCommissions = _.pick(this.wizardAppData.transaction, [
        "K_BrokerFee",
        "K_BrokerFeeGst",
      ]);
      this.startPageLoading();

      // Apply fees to all quotes
      this.$renewalDataService
        .applyBrokerFeeToAllQuotes(feesAndCommissions)
        .then(({ data }) => {
          let updatedStatus = data.result;
          if (updatedStatus) {
            // Update appData flags
            this.wizardAppData.statusFlags[updatedStatus.K_StatusFlag] = true;

            // Update UI transaction status
            let txn = this.wizardAppData.transaction;
            txn.K_Status = updatedStatus.K_Status;
            txn.K_StatusDetails = updatedStatus.K_StatusDetails;
            txn.K_NextStep = updatedStatus.K_NextStep;
            txn.K_DueInWeek = updatedStatus.K_DueInWeek;
            txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;
            this.updateUiTransactionStatus();
          }

          this.$renewalDataService
            .refreshQuotes()
            .then(({ data }) => {
              this.wizardAppData.quotes = data.result;
              this.stopPageLoading();

              this.updateWizardAppDataString();
            })
            .catch((error) => {
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setRecommendedQuote: function (quote) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      this.$set(quote, "saving", true);
      let quoteId = quote.id;
      this.$renewalDataService
        .setRecommendedQuote(quote.id)
        .then(() => {
          this.$renewalDataService
            .refreshQuotes()
            .then(({ data }) => {
              this.wizardAppData.quotes = data.result;
              let updatedQuote = _.find(this.wizardAppData.quotes, {
                id: quoteId,
              });
              if (updatedQuote) {
                this.$set(updatedQuote, "saving", false);
                this.$set(updatedQuote, "K_RecommendedQuote", true);
              }

              this.updateWizardAppDataString();
            })
            .catch((error) => {
              this.$set(quote, "saving", false);
              this.handleError(error);
            });
        })
        .catch((error) => {
          this.$set(quote, "saving", false);
          this.handleError(error);
        });
    },

    createQuoteComparisonTable: function () {
      this.clearError();
      if (this.isWizardReadOnly) {
        return;
      }

      // Check if a recommended-quote exists
      let recommendedQuote = _.find(this.wizardAppData.quotes, {
        K_RecommendedQuote: true,
      });
      if (!recommendedQuote || !recommendedQuote.id) {
        this.setError("Please set one of the quotes as the recommended quote.");
        return;
      }
      this.clearError();
      this.startPageLoading();
      this.$renewalDataService
        .getQuoteComparisonData()
        .then(({ data }) => {
          this.wizardAppData.quoteComparisonData = data.result;

          this.$renewalDataService.saveQuoteComparisonData(this.wizardAppData.quoteComparisonData)
          .catch((error) => {
            this.handleError(error);
          });

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    requestBrokerAdviceReview: function (brokerAdviceReviewData) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();
      this.startPageLoading();
      this.$renewalDataService
        .requestBrokerAdviceReview(
          brokerAdviceReviewData.reviewer,
          brokerAdviceReviewData.comments
        )
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    approveOrRejectBrokerAdvice: function (brokerAdviceManagerResponseData) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();
      this.startPageLoading();
      this.$renewalDataService
        .approveOrRejectBrokerAdvice(
          brokerAdviceManagerResponseData.status,
          brokerAdviceManagerResponseData.comments
        )
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    acceptQuote: function (quote) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();
      this.startPageLoading();
      this.$renewalDataService
        .acceptQuote(quote.id)
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    refreshWizardAppData: function () {
      this.$renewalDataService
        .getWizardAppData()
        .then(({ data }) => {
          this.wizardAppData = data.result;
          this.wizardAppDataString = stringify(this.wizardAppData);

          // Update transaction status on the header
          this.updateUiTransactionStatus();

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    finaliseTransaction: function () {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      this.startPageLoading();
      this.$renewalDataService
        .finaliseTransaction()
        .then((/*{ data }*/) => {
          // Refresh wizard-app-data
          this.refreshWizardAppData();

          this.$nextTick(() => {
            Swal.fire({
              title: "Renewal Finalised",
              html: `<p class="text-justify">This Renewal transaction has been finalised.</p><br/>
                   <span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showCancelButton: false,
              showCloseButton: false,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getNextTransactionInBatch: function () {
      this.clearError();

      this.$commonDataService
        .getNextTransactionInBatch(this.$route.query.batch)
        .then(({ data }) => {
          let nextBatchTransaction = data.result;
          if (nextBatchTransaction) {
            this.$store.commit("setNextBatchTransaction", nextBatchTransaction);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    openReopenTransactionDialog: function () {
      this.$refs["reopenTransactionDialog"].show();
    },

    reopenTransaction: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.reopenReason) {
        this.startPageLoading();
        this.$renewalDataService
          .reopenTransaction(this.reopenReason)
          .then((/*{ data }*/) => {
            this.$refs["reopenTransactionDialog"].hide();
            // Refresh wizard-app-data
            this.refreshWizardAppData();
          })
          .catch((error) => {
            this.handleError(error);
            this.reopenReason = null;
            this.$refs["reopenTransactionDialog"].hide();
          });
      }
    },

    showClosePageButton: function () {
      return this.isWizardReadOnly && window.opener && this.isLastPage;
    },

    closePage: function (e) {
      e.preventDefault();
      if (window.opener) {
        window.close();
      }
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function (errorObj) {
      this.stopPageLoading();
      let errorMsg = null;
      const uiErrorPrefix = "UI_ERROR:";

      if (errorObj) {
        if (
          typeof errorObj === "string" &&
          errorObj.startsWith(uiErrorPrefix)
        ) {
          errorMsg = errorObj.substring(uiErrorPrefix.length, errorObj.length);
        } else {
          let responseStatus = _.get(errorObj, "response.status");
          if (
            responseStatus === 401 ||
            (errorObj.message && errorObj.message.includes("status code 401"))
          ) {
            errorMsg =
              "This session is invalid or expired.\n\nPlease close this window & resume the transaction by using the relevant Process button.";
          } else {
            let responseResult = _.get(errorObj, "response.data.result");
            if (_.isString(responseResult)) {
              if (responseResult.startsWith(uiErrorPrefix)) {
                errorMsg = responseResult.substring(
                  uiErrorPrefix.length,
                  responseResult.length
                );
              }
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function () {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function () {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },

    getClientDeclarationEmailTemplate: function () {
      this.$commonDataService
        .getEmailTemplate("PRE_RENEWAL_DECLARATION")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    getHoldingInsurerQuoteSlipEmailTemplate: function () {
      this.$commonDataService
        .getEmailTemplate("SEND_QUOTE_SLIP_HOLDING")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    getInsurerQuoteSlipEmailTemplate: function () {
      this.$commonDataService
        .getEmailTemplate("SEND_QUOTE_SLIP")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    getConfirmationToInsurerEmailTemplate(acceptedInsurerContact) {
      const additionalData = {
        acceptedInsurerContact: acceptedInsurerContact
      };
      this.$commonDataService
        .getEmailTemplate("RENEWAL_INSURER_CONFIRMATION_REQUEST", additionalData)
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    getClosingToInsurerEmailTemplate(acceptedInsurerContact) {
      const additionalData = {
        acceptedInsurerContact: acceptedInsurerContact
      };
      this.$commonDataService
        .getEmailTemplate("RENEWAL_INSURER_CLOSING", additionalData)
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    async save(quote) {
      if (this.isWizardReadOnly) {
        return;
      }
      this.clearError();

      try {
        const response = await this.$renewalDataService.saveQuoteDetails(quote);
        // Refresh wizard-app-data
        await this.refreshWizardAppData();

        this.$emit('quoteRankingSaved', { quoteId: quote.id });

        return response;
      } catch (error) {
        this.handleError(error);
      }
    },

    handleSave(quote) {
      console.log(`performing save for quote ${quote.id}`);
      this.saveDebounced(quote);
    },

    async onSaveQuoteRanking(quote) {
      try {
        await this.handleSave(quote);
        this.$emit('quoteRankingSaved', { quoteId: quote.id });
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },
  },
};
</script>
